import 'bootstrap'
import './sass/main.scss'
import {BrowserRouter, Route, Routes} from 'react-router-dom'

import Auth from './pages/Auth/Auth'
import Carrello from './pages/Carrello/Carrello'
import Checkout from './pages/Checkout/Checkout'
import Faq from './pages/Faq/Faq'
import Home from './pages/Home/Home'
import Pdp from './pages/Pdp/Pdp'
import Plp from './pages/Plp/Plp'
import NotFound404 from './pages/404/NotFound404'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import Profile from './pages/Profile/Profile'
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions'
import ApiProvider from "@/hoc/Api/provider";
import CheckoutCompleted from "@/pages/CheckoutCompleted/CheckoutCompleted";
import StoreProvider from "@/hoc/Store/provider";
import LoginProvider from "@/hoc/Login/LoginProvider";
import SeoProvider from "@/hoc/Seo/provider";
import {TeamProvider} from "@/hoc/Team/TeamProvider";
import {CookiesProvider} from "@/hoc/Cookies/Cookies";

const App = () => {
    return (
        <TeamProvider eventIssuer={process.env.REACT_APP_EVENT_ISSUER || undefined}>
            <ApiProvider baseUrl={process.env.REACT_APP_API_BASE_PATH ?? "https://www.ticketag.dev/api"}>
                <StoreProvider>
                    <CookiesProvider>
                        <SeoProvider>
                            <BrowserRouter>
                                <Routes>
                                    <Route element={<Home/>} path='/' />
                                    <Route element={<Plp/>} path='/Partite'/>
                                    <Route element={<Pdp/>} path='/Partita/:id'/>
                                    <Route element={<Auth/>} path='/Auth/*'/>
                                    <Route element={<LoginProvider><Profile/></LoginProvider>} path='/Profilo/*'/>
                                    <Route element={<LoginProvider><Carrello/></LoginProvider>} path='/Carrello'/>
                                    <Route element={<LoginProvider><CheckoutCompleted/></LoginProvider>} path='/Checkout/:id'/>
                                    <Route element={<LoginProvider><Checkout/></LoginProvider>} path='/Checkout'/>
                                    <Route element={<TermsAndConditions/>} path='/Termini-e-Condizioni'/>
                                    <Route element={<PrivacyPolicy/>} path='/Privacy-Policy'/>
                                    <Route element={<Faq/>} path='/Faq'/>
                                    <Route element={<NotFound404 />} path='*' />
                                </Routes>
                            </BrowserRouter>
                        </SeoProvider>
                    </CookiesProvider>
                </StoreProvider>
            </ApiProvider>
        </TeamProvider>

    )
}

export default App
